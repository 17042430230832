.GameFrame2{
    display: grid;
    grid-template-columns: 0.5fr 5fr 1fr;
}

.DropListGame2 {
    font-size: 2vw;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 35vw;
    grid-gap: 1vw;
}

.DragObjectGame2 {
    background-color:  #b5f4e1;
    padding: 1vw;
    border: 0.1vw solid #ccc;
    margin-bottom: 1vw;
    display: inline-block;
    border-radius: 1vw;
}

.wordsColumnGame2{
    font-size: 3vw;
    display: grid;
    grid-template-columns: 1fr;

}

.transColumnGame2{
    font-size: 2vw;
    display: grid;
    grid-template-columns: 1fr;

}

.wordsColumnElementGame2 {

    border: 0.1vw solid #ffffff;
    padding: 1vw;
    height:2.5vw;
    margin-bottom:1vw;

}

.DropZoneGame2{
    /* margin-bottom: 1vw; */
    height:2.5vw;
    font-size: 2vw;
    padding: 1vw;
    border: none;
    border-bottom: 0.2vw dashed #333;
    /* border-radius: 1vw; */
    vertical-align:middle;
    margin-bottom:1vw;


}

.game2SubmitButton > .button{
    height: 5vw;
    width: 35vw;
   
}

.game2SubmitButton{
    justify-content: center;
    display: grid;
}

.DropZoneGameElement2{
    /* background-color:  #b5f4e1; */
    padding: 0vw 0.4vw 1vw 0.4vw;
    /* border: 0.1vw solid #ccc; */
    border: 1vw dashed transparent;
    /* margin-bottom: 1vw; */
    /* display: inline-block; */
    border-radius: 1vw;
    /* width: 10vw; */
    display: grid;
    grid-template-columns: 1fr 1fr;
}