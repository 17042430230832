.base_vocab_list{
    font-size:2.5vw;
    font-weight: 600;
    padding: 1vw;
    background-color: #fcecf5;
    border-radius: 10px;

}

.Gifs{
    display: grid;
    grid-template-columns: 1fr;
    margin: 1vw auto;
}

.clictBaseWord{
    margin-right: 0%;
    /* text-align: right; */
    display: grid;
    color:gray;
    background-color: #fcecf5;
    border: none;
    font-size:1.5vw;
}


.Modal{
    display: grid;
    margin-left: 40vw;
    width: 30vw;
    height: 30vw;
    padding: 2vw;
    border-radius: 20px;
    margin-top: 10vw;
    background-color: rgb(255, 255, 255);
    border: solid medium grey;
    outline: none;
    /* text-align: center; */
    font-size: 2vw;
    overflow-y: auto;
}


.closeModal {
	margin-top: 3%;
	width: 24px;
	height: 24px;
	opacity: 0.5;
	cursor: pointer;
    transition: opacity ease 0.5s;
    
	&:hover {
		opacity: 1;
	}
}

.closeModal::before,
.closeModal::after {
	content: '';
	position: absolute;
	display: grid;
	width: 30px;
	height: 4px;
	background: #000;
}

.closeModal::before {
    transform: rotate(45deg);
}

.closeModal::after {
    transform: rotate(-45deg);
}

.ModalCardImg{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin:0%;
    padding:0%;
    font-weight: bold;
    font-size: 3vw;
    text-align: center;
}

.Gifs>img {

    width: 80%;
    margin-top: 1vw;
    margin: auto;
}

.ScrollGifs{
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
}


.ModalCardImg img{
    width:50%;
}

.zoom-effect2 {
    transition: transform 0.5s;
  }
  
.zoom-effect2:hover {
transform: scale(5);
/* position: absolute;
margin-top: 5vw;
margin-left: 5vw; */

}

.miniarrowLeft img{
    width: 5vw;
}

.miniarrowRight img{
    width: 5vw;
}

.miniarrowLeft, .miniarrowRight{
    margin: 5vw auto;
    opacity: 0.5;
}

.SearchField{
    border: solid medium grey;
    outline: none;
}

.SearchField:focus{
    border: solid medium black;

}