.TestDrag{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-size:2vw;
}

.Drop{
    display: grid;
    /* grid-template-rows: 1fr; */
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1vw;

}

.clictDeleteBlock{
    margin: 0%;
    /* text-align: right; */
    display: grid;
    color:rgb(0, 0, 0);
    background-color:rgba(0, 0, 0, 0);
    border: none;
    font-size:1.2vw;
}

.DropElement{
    border: 0.1vw solid #ccc;
    /* padding: 0.5vw; */
    text-align: center;
    border-radius: 1vw;
    margin: 0vw 1vw;
    background-color: lightgrey;
    align-items: center;
    font-size: 2vw;
    height:6vw;
    display: grid;
}

.DropElementAfter {
    border: 0.1vw solid #ccc;
    /* padding: 0.5vw; */
    text-align: center;
    border-radius: 1vw;
    margin: 0vw 1vw;
    background-color: #b5f4e1;
    align-items: center;
    font-size: 2vw;
    height:6vw;
    display: grid;
}

.Game1page{
    display: grid;
    grid-template-columns: 1fr;
}

.DragItem{
    cursor: move;
    border: 0.1vw solid #ccc;
    padding: 1vw;
    border-radius: 1vw;
    margin-right: 1vw;
    margin-top: 2vw;
    margin-bottom: 2vw;
    background-color: #b5f4e1;
    text-align:center;
} 

.DropZone {
    padding: 1vw;
    font-size: 2vw;
    text-align: center;
}

.GameFrame {
    width: 80vw;
    margin: 4vw 0vw;
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
}

.wrongDrop {
    color: red;
}

.game1SubmitButton > .button{
    width: 100%;
    height: 5vw;
}

.game1SubmitButton{
    text-align: center;
    margin: 0vw 2vw;
}

.Game1MainField{
    margin: 2vw;
    padding: 2vw;
    border: solid 0.3vw lightgrey;    
    border-radius: 1vw;
}